import React from "react";
import { NavLink } from 'react-router-dom';
const Footer = () => {
    return (
        <>
            <footer className="w-100 bg-light text-center">
                <p className="text-center">© Copyright 2022 - 9gunthe.com. All Rights Reserved | Terms and Conditions<br /></p>
                <NavLink to="/login" className="">Admin Login</NavLink>
            </footer>

        </>
    );
};

export default Footer;