import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Home from "./Home";
import Contact from "./Contact";
import Service from "./Service";
import About from "./About";
import Navbar from "./Navbar";
import { Route, Switch, Redirect } from "react-router-dom";
import Footer from "./Footer";
import Enquiry from "./Enquiry";
import Login from "./Login";

const App = () => {
  return (
    <>
    <Navbar />
    <Route exact path="/login" component={Login}></Route>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/about" component={About}></Route>
        <Route exact path="/service" component={Service}></Route>
        <Route exact path="/contact" component={Contact}></Route>
        <Route exact path="/enquiry" component={Enquiry}></Route>
       
        <Redirect to="/" />
      </Switch>
      <Footer/>
    </>
  )
}

export default App;