import React from 'react';
import { NavLink } from 'react-router-dom';

const Card = (props) => {
    return (
        <>
                            <div className="col-md-3 col-10 mx-auto">
                                <div className="card">
                                    <img src={props.imgsrc} class="card-img-top" alt="..." />
                                    <div className="card-body">
                                        <h5 className="card-title font-weight-bold">{props.title}</h5>
                                        <p className="card-text"><strong>Area:  </strong>{props.area}</p>
                                        <p className="card-text"><strong>Location:  </strong>{props.location}</p>
                                        <NavLink to="/enquiry" class="btn btn-primary">Enquiry</NavLink>                               </div>
                                </div>
                            </div>             
        </>
    )
}

export default Card;