import React from "react";
import web from "../src/Images/home4.jpg";
import { NavLink } from 'react-router-dom';

const About = () => {
    return (
        <>
            <section id="header" className="d-flex align-items-center">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-10 mx-auto">
                            <div className="row">
                            {/* left column */}
                            <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-l d-flex justify-content-center flex-column ">
                                <h2 className="my-3">Founded in 2020, 9Gunthe.com is most innovative real estate online platform for customers to serach and find the plots, rentals, resale homes etc. </h2>
                                <h2 className="my-3">
                                It is an online forum where buyers, sellers and brokers/agents can exchange information about 
                                    real estate properties quickly, effectively and inexpensively.
                                </h2>
                                <h2 className="my-3">
                                <strong>Vision:</strong>
                                </h2>
                                <h2 className="my-3">
                                <strong>Mission:</strong>
                                </h2>
                                <div className="mt-3">
                                    <NavLink className="btn-get-started" to="/service"> Get Started</NavLink>
                                </div>
                            </div>
                            {/* right column */}
                            <div className="col-md-6 order-1 order-lg-2 header-img">
                                <img src={web} className="img-fluid animated" alt="Home Img"/>

                            </div>
                            </div>
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
};

export default About;