import React from "react";
import web from "../src/Images/home1.jpg";
import Common from "./Common";

const Home = () => {
    return (
        <>
        <Common 
        name="Complete Your Dream With"
        imgsrc={web}
        visit="/service"
        btname="Get Started"
        />
        </>
    );
};

export default Home;