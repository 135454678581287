import React from "react";
import web from "../src/Images/bg1.jpg";
import { NavLink } from 'react-router-dom';

const Contact = () => {
    return (
        <>
            <section id="header" className="d-flex align-items-center">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-10 mx-auto">
                            <div className="row">
                                {/* left column */}
                                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-l d-flex justify-content-center flex-column ">
                                    <h2 className="my-3"><strong>Contact Us</strong> </h2>

                                    <p>
                                       <h5> <b>Email:</b> <NavLink to="mailto:webmaster@example.com">webmaster@example.com</NavLink>.<br/>
                                            <b>Visit us at:</b><br/>
                                                9gunthe.com<br/>
                                                   Shrirampur 413709<br/>
                                                        Maharashtra, India
                                                    </h5>
                                                    </p>

                                                    <h2 className="my-3">
                                                     For Enquiry Call us on:                      
                                                    </h2>
                                                    <h4 className="my-1 brand-name">
                                                        +91 9511207931, +91 8530353140
                                                    </h4>

                                                </div>
                                                {/* right column */}
                                                <div className="col-md-6 order-1 order-lg-2 header-img">
                                                    <img src={web} className="img-fluid animated" alt="Home Img" />

                                                </div>
                                            </div>
                                        </div>

                                </div>

                            </div>

                        </section>
                    </>
                    );
};

                    export default Contact;