import React from 'react';
import Sdata from './Sdata';
import Card from './Card';

const Service = () => {
    return (
        <>
            <div className="my-5">
                <h1 className="text-center"> Property (Plots/Land)</h1>
            </div>
            <div className="container-fluid mb-5">
                <div className="row">
                    <div className="col-10 mx-auto">
                        <div className="row gy-5">
                            {
                                Sdata.map((val,ind)=>{
                                    return <Card key={ind}
                                    title={val.title}
                                    imgsrc={val.imgsrc}
                                    area={val.area}
                                    location={val.location}
                                    />
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Service;