import React from "react";
import { useState } from "react";


const Enquiry = () => {

    const [data, setData] = useState({
        name: "",
        contact: "",
        msg: "",
    });

    const InputEvent = (event) => {
        const { name, value } = event.target;
        setData((preVal) => {
            return {
                ...preVal,
                [name]: value,
            };
        });
    };

    const formsubmit = (e) => {
       alert(`name:${data.name} contact:${data.contact}`);
      
     };
    return (
        <>
            <div className="my-5">
                <h1 className="text-center">Enquiry</h1>
            </div>
            <div className="container contact_div">
                <div className="row">
                    <div className="col-md-4 col-8 mx-auto">
                        <form onSubmit={formsubmit}>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Name</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" name="name" value={data.name} onChange={InputEvent} placeholder="Enter Your Name" />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Contact</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1" name="contact" value={data.contact} onChange={InputEvent} placeholder="Mobile No." />
                            </div>
                            {/* <div class="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleFormControlInput1" placeholder="name@example.com"/>
                            </div> */}
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlTextarea1" className="form-label">Comment(Optional)</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" name="msg" value={data.msg} onChange={InputEvent}></textarea>
                            </div>
                            <div className="col-12 text-center">
                                <button className="btn btn-outline-primary " type="submit">Submit form</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Enquiry;