
import plot1 from "../src/Images/plot1.jpg";
import plot2 from "../src/Images/plot2.jpg";
import plot3 from "../src/Images/plot3.jpg";
import plot4 from "../src/Images/plot4.jpg";
import plot5 from "../src/Images/plot5.jpg";


const Sdata=[
{
    title:"Plot",
    imgsrc: plot1,
    area:"4 Acre",
    location:"Agre land At wakadi Tal Rahata dist ahmednagar"
},
{
    title:"Plot",
    imgsrc: plot2,
    area:"10 Acre",
    location:" At/Post: Wakadi Tal rahata dist ahmednagar "
},
{
    title:"bungalow",
    imgsrc: plot3,
    area:"NA",
    location:"At/P: Wakadi Tal rahata dist ahmednagar"
},
{
    title:"House and 6 R plot",
    imgsrc: plot4,
    area:"NA",
    location:"A/P: Dhangarwadi Tal: Rahata Dist: Ahmednagar"
},
{
    title:"Flat 1 BHK",
    imgsrc: plot5,
    area:"680 Sq.ft.",
    location:"ShriNiwas Residency Nashik Dindori road"
}, 
];

export default Sdata;