import React from 'react';
import { NavLink } from "react-router-dom";
import web from "../src/Images/bg1.jpg";

const Login = () => {
    return (
        <div className="container-fluid">
        <div className="row">

            <div className="col-10 mx-auto">
                <div className="row">
                {/* left column */}
                <div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-l d-flex justify-content-center flex-column ">
                    <h1> <strong className="brand-name">9Gunthe</strong></h1>
                    <h2 className="my-3">
                        We Are Life Changers. Helping you find the property of your dreams
                    </h2>
                    <div className="mt-3">
                        <NavLink className="btn-get-started" to="">ABC</NavLink>
                    </div>
                </div>
                {/* right column */}
                <div className="col-md-6 order-1 order-lg-2 header-img">
                    <img src={web} className="img-fluid animated" alt="Home Img"/>

                </div>
                </div>
            </div>

        </div>

    </div>
    );
};

export default Login;
